import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './TopHero.module.scss'
import { hyphenate } from '../../../utils'
import ReactPlayer from 'react-player'

const TopHero = ({ className, image, title, paragraph, video, mobileImage }) => {
  const hasContent = paragraph || title

  return (
    <div className={classnames(className, styles.container)}>
      <div className='container'>
          <div className={classnames(styles.contentContainer, 'row mx-0')}>
              {!!image && (
                <div className='col-12 px-0'>
                  <ImageContainer data={image.src} />
                </div>
              )}

              {!!video && (
                <div className='col-12 px-0 mb-5 pb-3 d-block'>
                  <VideoContainer data={video} mobileImage={mobileImage} />
                </div>
              )}
          </div>


        {hasContent && (
          <div className={styles.textContainer}>
            <div className='row'>
              {title &&
                <div className={classnames('col-md-12', { 'mt-5': image === false })}>
                  <h1 className={styles.heading}>{hyphenate(title)}</h1>
                </div>
              }
              {paragraph &&
                <div
                  className={classnames(
                    styles.paragraph,
                    'col-md-9 col-sm-12'
                  )}
                  dangerouslySetInnerHTML={{ __html: paragraph }}
                />
              }
            </div>
          </div>
        )
        }

      </div>
    </div>
  )
}

const ImageContainer = ({ data, className }) => {
  return (
    <div className={classnames(className, styles.image)} style={{ backgroundImage: `url(${data})` }} />
  )
}

const VideoContainer = ({ data, mobileImage }) => {
  return (
    <>
      <ReactPlayer className='d-none d-md-block' playing loop muted width='100%' height='100%' url={data} />
      {mobileImage && (<ImageContainer data={mobileImage} className='d-block d-md-none' />)}
    </>
  )
}

TopHero.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string,
  paragraph: PropTypes.string
}

TopHero.defaultProps = {
  image: {},
  title: '',
  paragraph: ''
}

export default TopHero
